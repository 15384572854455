import styled from "styled-components";

export const InfoContainer = styled.div`
    color: #fff;
    height: auto;
    background: ${({ lightBg }) => (lightBg ? "#f9f9f9" : "#1e2761")};
    padding: 80px 0 50px;

    @media screen and (max-width: 768px) {
        padding: 50px 0;
    }
`;

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: auto;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;

    @media screen and (max-width: 768px) {
        margin-top: 40px;
    }
`;

export const InfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: "col1 col2";

    @media screen and (max-width: 768px) {
        height: auto;
        grid-template-areas: "col1 col1" "col2 col2";
    }
`;

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 10px;
    grid-area: col1;

    @media screen and (max-width: 480px) {
        padding: 0;
        margin-bottom: 0px;
    }
`;

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 10px;
    grid-area: col2;

    @media screen and (max-width: 480px) {
        padding: 0;
    }
`;

export const ResRow = styled.div`
    display: grid;
    grid-auto-columns: auto;
    align-items: center;
    padding: 20px;
    grid-template-areas: "col1 col2";

    @media screen and (max-width: 480px) {
        grid-template-areas: "col1 col1" "col2 col2";
    }
`;

export const ResCol1 = styled.div`
    margin-top: 0px;
    padding: 0 40px;
    grid-area: col1;
`;

export const ResCol2 = styled.div`
    margin-bottom: 0px;
    padding: 0 40px;
    grid-area: col2;
`;

export const ResLink = styled.div`
    margin-top: 10px;
`;

export const TextWrapper = styled.div`
    max-width: 600px;
    padding-top: 0px;
    padding-bottom: 60px;

    @media screen and (max-width: 480px) {
        padding-bottom: 10px;
    }
`;

export const CalendarWrapper = styled.div`
    width: auto;
    height: 480px;
    padding-top: 0px;
    padding-bottom: 40px;
    margin-bottom: 40px;
    justify-content: center;
    align-items: center;
`;

export const ContactWrapper = styled.div`
    width: auto;
    height: auto;
    padding-top: 40px;
    padding-bottom: 30px;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
`;

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`;

export const Subtitle = styled.h2`
    margin-bottom: 24px;
    font-size: 32px;
    line-height: 1.1;
    font-weight: 400;
    color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`;

export const TextBlock = styled.div`
    text-align: justify-center;
    max-width: 480px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: ${({ darkText }) => (darkText ? "#010606" : "#fff")};

    a {
        text-decoration: none;
        color: #891446;
        font-weight: 600;

        &:hover {
            color: grey;
            transition: 0.2s ease-in-out;
        }
    }
`;

export const ImgWrap = styled.div`
    max-width: 600px;
    height: 100%;
`;

export const Img = styled.img`
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;
    border-radius: 2px;
`;

export const FAQques = styled.div`
    font-weight: 600;
    margin-top: 10px;
`;
