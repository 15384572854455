import { Link } from "react-router-dom";
import styled from "styled-components";

export const FooterContainer = styled.footer`
    background-color: #101522;
    height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const FooterWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin: 0 auto;
    grid-gap: 16px;
    padding: 0 50px;
    max-width: 1000px;
    width: 100%;

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 0 20px;
    }
`;

export const SocialLogo = styled(Link)`
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    align-items: center;
    text-align: center;
    margin-bottom: 16px;
`;

export const WebsiteRights = styled.small`
    color: #fff;
    margin-bottom: 16px;
    text-align: center;
`;

export const Img = styled.img`
    width: auto;
    height: 60px;
    margin: 0 0 10px 0;
    padding-right: 0;
    border-radius: 2px;
`;
