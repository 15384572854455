import React from "react";

import {
    InfoContainer,
    InfoWrapper,
    ResRow,
    ResCol1,
    ResCol2,
    TextWrapper,
    TextBlock,
    Subtitle,
    ResLink,
} from "./InfoElements";

const ResourceInfo = ({ lightBg, lightText, darkText }) => {
    return (
        <InfoContainer lightBg={true} id={"resources"}>
            <InfoWrapper>
                <ResRow imgStart={false}>
                    <ResCol1>
                        <Subtitle>Kendo Federations</Subtitle>
                        <TextWrapper>
                            <TextBlock darkText={true}>
                                <ResLink>
                                    <a
                                        href="https://bckf.ca/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        British Columbia Kendo Federation
                                    </a>
                                </ResLink>
                                <ResLink>
                                    <a
                                        href="https://kendo-canada.com/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Canadian Kendo Federation
                                    </a>
                                </ResLink>
                                <ResLink>
                                    <a
                                        href="https://www.kendo.or.jp/en/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        All Japan Kendo Federation
                                    </a>
                                </ResLink>
                                <ResLink>
                                    <a
                                        href="http://www.kendo-fik.org/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        International Kendo Federation
                                    </a>
                                </ResLink>
                                <ResLink>
                                    <a
                                        href="https://www.auskf.org/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        All United States Kendo Federation
                                    </a>
                                </ResLink>
                            </TextBlock>
                        </TextWrapper>
                    </ResCol1>
                    <ResCol2>
                        <TextWrapper>
                            <TextBlock darkText={true}>
                                <Subtitle>Kendo Equipment</Subtitle>
                                <ResLink>
                                    <a
                                        href="https://budo-aoi.com/index.php"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        AOI Budogu
                                    </a>
                                </ResLink>
                                <ResLink>
                                    <a
                                        href="https://budogu-zen.ca/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Budogu Zen
                                    </a>
                                </ResLink>
                                <ResLink>
                                    <a
                                        href="http://www.bogushop.com/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Bogushop
                                    </a>
                                </ResLink>
                                <ResLink>
                                    <a
                                        href="https://www.e-bogu.com/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        E-Bogu
                                    </a>
                                </ResLink>
                                <ResLink>
                                    <a
                                        href="https://kendostar.com/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Kendo Star
                                    </a>
                                </ResLink>
                            </TextBlock>
                        </TextWrapper>
                    </ResCol2>
                </ResRow>
            </InfoWrapper>
        </InfoContainer>
    );
};

export default ResourceInfo;
