export const ImagesDesktop = [
    {
        image: require("../images/uvickidsshiai_20191229_1920x1080.jpg"),
        caption: "Kendo is for all Generations.   Come watch a Practice!",
        id: 1,
        lpos: "8%",
        bpos: "16%",
        maxcapwidth: "360px",
    },
    {
        image: require("../images/ckfjunior2023_1920x1080.jpg"),
        caption: "Attend competitions and seminars",
        id: 2,
        lpos: "73%",
        bpos: "75%",
        maxcapwidth: "480px",
    },
    {
        image: require("../images/misckeiko_1920x1200.jpg"),
        caption: "Practice, train hard and show your spirit!",
        id: 3,
        lpos: "66%",
        bpos: "75%",
        maxcapwidth: "480px",
    },
    {
        image: require("../images/uvic_outdoor_20210718_1920x1080.jpg"),
        caption: "Looking for something new?     Try out Kendo!",
        id: 4,
        lpos: "75%",
        bpos: "11%",
        maxcapwidth: "400px",
    },
];
