import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import HeroPage from "../components/HeroPage";
import AboutInfo from "../components/AboutInfo";
import PracticeInfo from "../components/PracticeInfo";
import InstructorInfo from "../components/InstructorInfo";
import FAQInfo from "../components/FAQInfo";
import ContactInfo from "../components/ContactInfo";
import ResourceInfo from "../components/ResourcesInfo";
import CalendarInfo from "../components/CalendarInfo";
import Footer from "../components/Footer";

const Home = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} />
            <HeroPage />
            <AboutInfo />
            <PracticeInfo />
            <InstructorInfo />
            <FAQInfo />
            <ContactInfo />
            <ResourceInfo />
            <CalendarInfo />
            <Footer />
        </>
    );
};

export default Home;
