import React from "react";

import {
    InfoContainer,
    InfoWrapper,
    InfoRow,
    Column1,
    Column2,
    TextWrapper,
    Heading,
    TextBlock,
    ImgWrap,
} from "./InfoElements";

const PracticeInfo = ({ lightBg, lightText, darkText }) => {
    return (
        <InfoContainer lightBg={true} id={"practice"}>
            <InfoWrapper>
                <InfoRow>
                    <Column1>
                        <TextWrapper>
                            <Heading lightText={false}>{"Practice Information"}</Heading>
                            <TextBlock darkText={true}>
                                New Courses start in January, May and September! Please
                                register with{" "}
                                <a
                                    href="https://vikesrec.ca/martial-arts/kendo"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Vikes Athletics &amp; Recreation
                                </a>
                                .
                                <br />
                                <br />
                                All practices are in the University of Victoria CARSA
                                building Fieldhouse (usually Zone 2A) unless otherwise
                                indicated. All levels are welcome at all practices.
                                <br />
                                <br />
                                Usual practice times are:
                                <br />
                                Tuesday and Friday 6:30-8:30pm
                                <br />
                                Sunday 3-5pm
                                <br />
                                <br />
                                These times (and the location) may change during holiday
                                periods (e.g. Christmas to New Years) and between school
                                terms.
                            </TextBlock>
                        </TextWrapper>
                    </Column1>
                    <Column2>
                        <ImgWrap>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2224.548831253407!2d-123.30975598169526!3d48.46709494963323!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x548f715bc828c1a7%3A0xa71496209eb83c8e!2sCARSA%20(Centre%20for%20Athletics%2C%20Recreation%20and%20Special%20Abilities)!5e0!3m2!1sen!2sca!4v1671227189431!5m2!1sen!2sca"
                                width="100%"
                                height="400"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                                title="google map"
                                samesite="none"
                            ></iframe>
                        </ImgWrap>
                    </Column2>
                </InfoRow>
            </InfoWrapper>
        </InfoContainer>
    );
};

export default PracticeInfo;
