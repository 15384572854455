import React from "react";
import { animateScroll as scroll } from "react-scroll";

import {
    FooterContainer,
    FooterWrap,
    SocialLogo,
    WebsiteRights,
    Img,
} from "./FooterElements";

import ImageLogo from "../images/uvickendologo.jpg";

const Footer = () => {
    const toggleHome = () => {
        scroll.scrollToTop();
    };

    return (
        <>
            <FooterContainer>
                <FooterWrap>
                    <SocialLogo to="/" onClick={toggleHome}>
                        <Img src={ImageLogo} alt={"UVic Kendo Logo"} />
                    </SocialLogo>
                    <WebsiteRights>
                        Victoria Kendo Club &copy; {new Date().getFullYear()}
                    </WebsiteRights>
                </FooterWrap>
            </FooterContainer>
        </>
    );
};

export default Footer;
