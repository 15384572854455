import React from "react";

import {
    InfoContainer,
    InfoWrapper,
    InfoRow,
    Column1,
    Column2,
    TextWrapper,
    Heading,
    TextBlock,
    FAQques,
} from "./InfoElements";

const FAQInfo = ({ lightBg, lightText, darkText }) => {
    return (
        <InfoContainer lightBg={true} id={"faq"}>
            <InfoWrapper>
                <InfoRow imgStart={false}>
                    <Column1>
                        <Heading lightText={false}>
                            {"Frequently Asked Questions"}
                        </Heading>
                        <TextWrapper>
                            <TextBlock darkText={true}>
                                <FAQques>
                                    Do I have to be a UVic Student to sign up?
                                </FAQques>
                                No, the Victoria Kendo Club is open to all members of the
                                community who wish to join.
                                <br />
                                <FAQques>
                                    What does it mean to be a 'Vikes Recreation member'?
                                </FAQques>
                                A Vikes Recreation member is anyone who has a Vikes
                                membership and therefore paid the recreation fee. If you
                                are a current UVic student or staff, you have most likely
                                paid this fee and are therefore eligible for a slightly
                                discounted course price. If you are not involved with UVic
                                and have not paid the recreation fee, then you are
                                considered a community member and must pay the slightly
                                higher fee for the course.
                                <br />
                                <FAQques>How old do I have to be?</FAQques>
                                All ages from 6 to adult are welcome to practice with us.
                                Kendo can be started at any stage of life, so please drop
                                by a practice to see for yourself! If you are younger than
                                16 please look at our{" "}
                                <a
                                    href="https://activeliving.uvic.ca/Program/GetProgramDetails?courseId=f6e06ca8-4bd9-445e-ab5d-005fb95cfa9a"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Kendo For Kids course
                                </a>
                                .
                            </TextBlock>
                        </TextWrapper>
                    </Column1>
                    <Column2>
                        <TextWrapper>
                            <TextBlock darkText={true}>
                                <FAQques>How do I sign up?</FAQques>
                                Registration can be done in person at front desk of CARSA
                                or{" "}
                                <a
                                    href="https://vikesrec.ca/martial-arts/kendo"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    online
                                </a>
                                .
                                <br />
                                <br />
                                Those with prior kendo experience from a CKF recognized
                                kendo club should sign up for the{" "}
                                <a
                                    href="https://activeliving.uvic.ca/Program/GetProgramDetails?courseId=dfaea4fe-ead7-4a57-bb4b-137f7af79ca4"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Kendo course
                                </a>
                                .
                                <br />
                                <br />
                                Those new to kendo should sign up for the{" "}
                                <a
                                    href="https://activeliving.uvic.ca/Program/GetProgramDetails?courseId=b65a3c21-d4e2-4803-a818-61da56a9edca"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Kendo Introduction course
                                </a>
                                .
                                <br />
                                <br />
                                <FAQques>What equipment do I need?</FAQques>
                                For the first few months, while you learn the basics, all
                                you will need is a shinai (bamboo sword) and comfortable
                                clothing that you can move in. There are some shinai
                                available for beginners to use, but it is recommended that
                                you purchase your own. You can purchase them at cost
                                through the club ($30-$40). When the instructor says you
                                are ready for bogu, you can either rent the equipment and
                                uniform from the club for a monthly fee ($10-$15/month),
                                or purchase your own.
                                <br />
                                <br />
                                We also welcome you to drop by at any scheduled practice
                                time. We'd be more than happy to answer any questions you
                                may have before or after practice.{" "}
                            </TextBlock>
                        </TextWrapper>
                    </Column2>
                </InfoRow>
            </InfoWrapper>
        </InfoContainer>
    );
};

export default FAQInfo;
