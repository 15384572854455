import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";

export const Nav = styled.nav`
    /* background: ${({ scrollNav }) => (scrollNav ? "#1e2761" : "transparent")}; */
    background: ${({ scrollNav }) => (scrollNav = "#1e2761")};
    height: 80px;
    margin-top: -80px;
    display: flex;
    justify-content: center;
    font-size: 1.1rem;
    position: sticky;
    top: 0;
    z-index: 10;

    @media screen and (max-width: 960px) {
        transition: 0.6s all ease;
    }
`;

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-width: 1100px;
`;

export const NavLogo = styled(LinkR)`
    color: #fff;
    justify-self: flex-start;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 20px;
    text-decoration: none;
    font-size: 1.25rem;
    font-weight: 500;
    font-family: "Noto Sans JP", sans-serif;
    text-shadow: 2px 2px 2px #000;
`;

export const Image = styled.img`
    width: auto;
    height: 80px;
`;

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
    }
`;

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const NavItem = styled.li`
    height: 80px;
`;

export const NavLinks = styled(LinkS)`
    font-size: 1.2rem;
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    /* padding: 4px 8px; */

    &.active {
        // maroon or dark pink
        /* color: #7a2048; */
        // violet red
        color: #891446;
        /* font-weight: bold; */
        text-shadow: 1px 1px 1px #000;
    }

    &:hover {
        color: grey;
        transition: 0.2s ease-in-out;
    }
`;
