import React, { useState, useEffect } from "react";
import { ImagesDesktop } from "./ImagesDesktop";
import { ImagesMobile } from "./ImagesMobile";
import { SliderContainer, SlideCaption } from "./ImageSliderElements";

const ImageSlider = ({ slides }) => {
    const [current, setCurrent] = useState(0);
    const length = slides.length;

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    };

    const SliderData = window.innerWidth >= 650 ? ImagesDesktop : ImagesMobile;

    // try this for auto playing slides
    useEffect(() => {
        setTimeout(nextSlide, 10000);

        return function () {
            clearTimeout(nextSlide);
        };
    });

    if (!Array.isArray(slides) || slides.length <= 0) {
        return null;
    }

    return (
        <SliderContainer>
            {SliderData.map((slide, index) => {
                return (
                    <div
                        className={index === current ? "slide active" : "slide"}
                        key={index}
                    >
                        {index === current && (
                            <div>
                                <img
                                    src={slide.image}
                                    alt="kendo pic"
                                    className="image"
                                />
                                <div>
                                    <SlideCaption
                                        lPos={slide.lpos}
                                        bPos={slide.bpos}
                                        cWidth={slide.maxcapwidth}
                                    >
                                        {slide.caption}
                                    </SlideCaption>
                                </div>
                            </div>
                        )}
                    </div>
                );
            })}
        </SliderContainer>
    );
};

export default ImageSlider;
