import React from "react";
import { HeroContainer, HeroBg } from "./HeroPageElements";

import ImageSlider from "./ImageSlider";
import { ImagesDesktop } from "./ImagesDesktop";
import { ImagesMobile } from "./ImagesMobile";

const HeroPage = () => {
    const SliderData = window.innerWidth >= 650 ? ImagesDesktop : ImagesMobile;

    return (
        <HeroContainer id="home">
            <HeroBg>
                <ImageSlider slides={SliderData} />;
            </HeroBg>
        </HeroContainer>
    );
};

export default HeroPage;
