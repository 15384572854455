import React from "react";

import { InfoContainer, InfoWrapper, Heading, CalendarWrapper } from "./InfoElements";

function CalendarInfo({ lightBg, lightText, darkText }) {
    return (
        <InfoContainer lightBg={false} id={"calendar"}>
            <InfoWrapper>
                <CalendarWrapper>
                    <Heading lightText={true}>{"Victoria Kendo Calendar"}</Heading>
                    <iframe
                        src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FVancouver&showTitle=0&src=dmljdG9yaWFrZW5kb0BnbWFpbC5jb20&color=%237CB342"
                        style={{ border: 0 }}
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        scrolling="no"
                        samesite="none"
                        title="Kendo Calendar"
                    ></iframe>
                </CalendarWrapper>
            </InfoWrapper>
        </InfoContainer>
    );
}

export default CalendarInfo;
