import React from "react";

import {
    InfoContainer,
    InfoWrapper,
    Heading,
    TextBlock,
    ContactWrapper,
} from "./InfoElements";

const ContactInfo = ({ lightBg, lightText, darkText }) => {
    return (
        <InfoContainer lightBg={false} id={"contact"}>
            <InfoWrapper>
                <ContactWrapper>
                    <Heading lightText={true}>{"Contact Us"}</Heading>

                    <TextBlock darkText={false}>
                        If you have any questions please feel free to email us at{" "}
                        <a href="mailto:victoriakendo@gmail.com" rel="noreferrer">
                            victoriakendo@gmail.com
                        </a>
                    </TextBlock>
                    <TextBlock darkText={false}>
                        We have a Facebook group for our active kendo members and
                        parents/guardians of junior members. You can find us there on
                        Facebook at{" "}
                        <a
                            href="https://www.facebook.com/groups/15470220561/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            UVIC Kendo Club
                        </a>
                    </TextBlock>
                    <TextBlock>
                        For UVIC/Carsa membership or course registration information
                        please call the CARSA staff at 250-472-4000.
                    </TextBlock>
                </ContactWrapper>
            </InfoWrapper>
        </InfoContainer>
    );
};

export default ContactInfo;
