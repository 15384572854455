import styled from "styled-components";

export const SliderContainer = styled.div`
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const SliderImg = styled.img`
    width: 1000px;
    height: 600px;
    border-radius: 10px;
`;

export const SlideCaption = styled.div`
    color: #fff;
    z-index: 10;
    font-size: 2.7rem;
    position: absolute;
    font-weight: 500;
    left: ${({ lPos }) => lPos};
    bottom: ${({ bPos }) => bPos};
    width: ${({ cWidth }) => cWidth};
    /* background: rgba(0, 0, 0, 0); */
    text-shadow: 1px 1px 1px #000;

    @media screen and (max-width: 480px) {
        font-size: 2rem;
        width: ${({ cWidth }) => cWidth};
        text-align: center;
        left: ${({ lPos }) => lPos};
        bottom: ${({ bPos }) => bPos};
        text-shadow: 2px 2px 2px #000;
    }
`;
