import React from "react";

import {
    InfoContainer,
    InfoWrapper,
    InfoRow,
    Column1,
    Column2,
    TextWrapper,
    Heading,
    TextBlock,
    ImgWrap,
    Img,
} from "./InfoElements";

const AboutInfo = ({ lightBg, lightText, darkText }) => {
    return (
        <InfoContainer lightBg={false} id={"about"}>
            <InfoWrapper>
                <InfoRow>
                    <Column1>
                        <TextWrapper>
                            <Heading lightText={true}>{"About Us"}</Heading>
                            <TextBlock darkText={false}>
                                Kendo was first introduced to Victoria in 1986 by Ted
                                Davis sensei and practices have been held continuously at
                                the University of Victoria since then. UVic Kendo meets
                                three times a week at the University of Victoria and is
                                open to all members of the community of ages 6 and up.
                                <br /> <br />
                                Kendo is a Japanese martial art based on the use of the
                                samurai sword. In kendo, we use protective armor (bogu)
                                modeled on samurai armor, and bamboo sticks (shinai) which
                                represent swords. The primary purposes of kendo are to
                                develop awareness, centeredness, wholeheartedness,
                                endurance, concentration, respect for others, and
                                self-confidence.
                                <br /> <br />
                                Kendo can be approached in different ways as a competitive
                                sport, for physical health, for spiritual and mental
                                development, or as an introduction to Japanese culture and
                                philosophy.
                            </TextBlock>
                        </TextWrapper>
                    </Column1>
                    <Column2>
                        <ImgWrap>
                            <Img
                                src={require("../images/uvickendo20220823.jpg")}
                                alt={"club photo"}
                            />
                        </ImgWrap>
                    </Column2>
                </InfoRow>
            </InfoWrapper>
        </InfoContainer>
    );
};

export default AboutInfo;
