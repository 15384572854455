export const ImagesMobile = [
    {
        image: require("../images/uvickidsshiai_20191229_1700x3024.jpg"),
        caption: "Kendo is for all Generations.  Come watch a practice!",
        id: 1,
        lpos: "10%",
        bpos: "15%",
        maxcapwidth: "290px",
    },
    {
        image: require("../images/ckfjunior2023_1700x3024.jpg"),
        caption: "Attend Competitions and Seminars",
        id: 2,
        lpos: "17%",
        bpos: "62%",
        maxcapwidth: "260px",
    },
    {
        image: require("../images/misckeiko_1700x3024.jpg"),
        caption: "Practice, train hard and show your spirit!",
        id: 3,
        lpos: "18%",
        bpos: "31%",
        maxcapwidth: "240px",
    },
    {
        image: require("../images/uvic_ckfjunior_20220528_1700x3022.jpg"),
        caption: "Looking for something new?  Try out Kendo!",
        id: 4,
        lpos: "17%",
        bpos: "20%",
        maxcapwidth: "270px",
    },
];
